//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/* @todo implement lazy pagination (infinite scroll) */

import Breadcrumbs from '~/components/UI/Breadcrumbs.vue'

export default {
  name: 'SearchPage',
  components: {
    Breadcrumbs,
    ProductItemCard: () =>
      import('~/components/modules/Cards/ProductItemCard.vue'),
  },
  data() {
    return {
      products: [],
      totalItems: 0,
      showSort: false,
      showPerPage: false,
      perPage: 15,
      orderByFilter: null,
      orderBy: [
        {
          slug: 1,
          item: 'name',
          type: 'ASC',
          name: this.$t('nameAToZ'),
        },
        {
          slug: 2,
          item: 'name',
          type: 'DESC',
          name: this.$t('nameZToA'),
        },
        {
          slug: 3,
          item: 'created_at',
          type: 'ASC',
          name: this.$t('newFirst'),
        },
        {
          slug: 4,
          item: 'popular',
          type: 'DESC',
          name: this.$t('popular'),
        },
      ],
      perPageItems: [
        15,
        30,
        60,
        90,
      ],
    }
  },
  fetch() {
    return this.fetchRoom()
  },
  computed: {
    name() {
      return this.$route && this.$route.params && this.$route.params.query
        ? this.$route.params.query
        : ''
    },
  },
  mounted() {

  },
  methods: {
    clearAll() {
      for (const key in this.filters) {
        this.filters[key] = []
      }
      this.filtersArr = []
      this.$refs['sidebar-right'].hide()
    },
    setSort(item) {
      if (this.orderByFilter && this.orderByFilter.slug === item.slug) {
        this.orderByFilter = null
      } else {
        this.orderByFilter = item
      }
      this.getProducts()
    },
    setPerPage(item) {
      this.closePerPage()
      if (this.perPage !== item) {
        this.perPage = item
        this.getProducts()
      }
    },
    setFilter(item, type, a) {
      if (!this.filters[type].some(i => i.slug === item.slug)) {
        this.filters[type].push(item)
        const t = item
        item.type = type
        this.filtersArr.push(t)
      } else {
        this.filters[type] = this.filters[type].filter(i => i.slug !== item.slug)
        this.filtersArr = this.filtersArr.filter(i => i.slug !== item.slug)
      }
      this.getProducts()
    },
    getProducts() {
      const params = {
        per_page: this.perPage,
        name: this.name,
      }
      if (this.orderByFilter) {
        params.orderBy = {}
        params.orderBy[this.orderByFilter.item] = this.orderByFilter.type
      }

      return this.$request.$get('/search', params).then(res => {

        this.products = res.data.map(item => {
          return {
            ...item,
            variations: item.variations.map((variation) => {
              return {
                ...variation,
                file: variation.files[0] ? variation.files[0].preview_url : '',
              }
            }),
          }
        })
        this.totalItems = res.meta.pagination.total
      })
    },
    closeCategories() {
      this.showCategories = false
    },
    closePrices() {
      this.showPrices = false
    },
    closeSort() {
      this.showSort = false
    },
    closePerPage() {
      this.showPerPage = false
    },
    fetchRoom() {
      const params = {
        per_page: this.perPage,
        name: this.name,
      }
      if (this.orderByFilter) {
        params.orderBy = {}
        params.orderBy[this.orderByFilter.item] = this.orderByFilter.type
      }
      return this.$request.$get('/search', params).then(res => {
        this.totalItems = res.meta.pagination.total

        this.products = res.data.map(item => {
          return {
            ...item,
            variations: item.variations.map((variation) => {
              return {
                ...variation,
                file: variation.files[0] ? variation.files[0].preview_url : '',
              }
            }),
          }
        })
      })
    },
    onClick(value) {
      const { id, data } = value
      const keys = ['labels', 'status', 'name', 'price_type', 'slug']
      const slide = this.items.find((item) => item.id === id)
      for (const key of keys) {
        slide[key] = data[key]
      }
      const variations = Object.keys(data.variations).filter(
        (key) => key !== 'primary',
      )
      slide.variations = data.variations[
        data.variations.primary ? data.variations.primary : variations[0]
      ].map((variation) => ({
        ...variation,
        file: variation.files[0]?.preview_url ? variation.files[0].preview_url : '',
      }))
    },
  },
}
