//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Breadcrumbs from '~/components/UI/Breadcrumbs.vue'
import {SET_BC} from '~/store/bc/types.bc'


export default {
  name: 'Index',
  components: {
    Breadcrumbs,
    RoomMiniatureItem: () =>
      import('~/components/modules/Miniatures/RoomMiniatureItem.vue'),
  },
  data() {
    return {
      rooms: [],
      title: '',
    }
  },
  fetch() {
    return this.fetchRoom()
  },
  mounted() {

  },
  methods: {
    fetchRoom() {
      return this.$request.$get(`/room/catalog/${this.$route.params.slug}`).then(res => {
        this.rooms = res.data.map(item => {
          return {
            ...item,
            src: item.files[0].url,
            productsCount: `${item.items_count} ${this.$t('products')}`,
          }
        })
        this.title = this.rooms.length ? this.rooms[0].category_name : ''
        this.$store.commit(`bc/${SET_BC}`, [
          {name: 'Rooms', slug: '/room/catalog'},
          {name: this.title, slug: '/'},
        ])
      })
    },

  },
}
