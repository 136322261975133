//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ModalRoom from '~/components/modules/ModalWindows/ModalRoom.vue'
import ProductAuthBlock from '~/components/pages/Product/components/ProductAuthBlock.vue'
import Breadcrumbs from '~/components/UI/Breadcrumbs.vue'
import {HAS_TOKEN} from '~/store/auth/types.auth'
import {SET_BC} from '~/store/bc/types.bc'

export default {
  name: 'Index',
  components: {
    ModalRoom,
    Breadcrumbs,
    ProductAuthBlock,
    RoomsSlider: () =>
      import('~/components/modules/Sliders/RoomsSlider.vue'),
    ProductItemCard: () =>
      import('~/components/modules/Cards/ProductItemCard.vue'),
  },
  data() {
    return {
      show: true,
      room: {},
      similar: [],
      products: [],
      navigation: [],
      vertical: false,
      top: 0,
      left: 0,
    }
  },
  fetch() {
    return this.fetchProduct()
  },
  computed: {
    isAuth() {
      return this.$store.getters[`auth/${HAS_TOKEN}`]
    },
  },
  watch: {
    isAuth() {
      this.fetchProduct()
    },
  },
  mounted() {
    // console.log('room', this.room)
  },
  methods: {
    setPosition(e, i, x, y) {
      const modal = document.querySelector(`.rif-marker-tooltip-wrap__${i}`)
      modal.style.top = 'unset'
      modal.style.bottom = 'unset'
      modal.style.left = 'unset'
      modal.style.right = 'unset'

      const imgW = document.querySelector('.room-page__image img').offsetWidth
      const imgH = document.querySelector('.room-page__image img').offsetHeight

      const modalW = modal.offsetWidth
      const modalH = modal.offsetHeight

      const pointL = imgW * x / 100
      const pointR = imgW - pointL
      const pointT = imgH * y / 100
      const pointB = imgH - pointT

      if (pointR >= modalW) {
        modal.style.left = window.innerWidth <= 1024 ? '16px' : '20px'
      } else if (pointL >= modalW) {
        modal.style.right = window.innerWidth <= 1024 ? '16px' : '20px'
      }


      if (pointT >= modalH / 2 && pointB > modalH / 2) {
        modal.style.bottom = '-' + modalH / 2 + 'px'
      } else if (pointT <= modalH / 2 || modalH > imgH) {
        modal.style.top = '-' + pointT + 'px'
      } else if (pointB <= modalH / 2) {
        modal.style.bottom = '-' + pointB + 'px'
      }

    },
    onClick(value) {
      const {id, data} = value
      const keys = ['labels', 'status', 'name', 'price_type', 'slug']
      const slide = this.items.find((item) => item.id === id)
      for (const key of keys) {
        slide[key] = data[key]
      }
      const variations = Object.keys(data.variations).filter(
        (key) => key !== 'primary',
      )
      slide.variations = data.variations[
        data.variations.primary ? data.variations.primary : variations[0]
      ].map((variation) => ({
        ...variation,
        file: variation.files[0]?.url ? variation.files[0].url : '',
      }))
    },
    fetchProduct() {
      const slug = this.$route.params.slug
      return this.$request.$get(`/room/${slug}`).then(({data}) => {
        const {similar, breadcrumbs, products} = data
        this.similar = similar.map((slide) => ({
          ...slide,
          src: slide.files[0].url,
          productsCount: `${slide.items_count} ${this.$t('products')}`,
        }))
        this.products = products.map(item => {
          return {
            ...item,
            variations: item.variations.map((variation) => {
              return {
                ...variation,
                file: variation.files[0] ? variation.files[0].url : '',
              }
            }),
          }
        })
        this.$store.commit(`bc/${SET_BC}`, [
          ...breadcrumbs,
          {name: data.name, slug: data.slug},
        ])
        this.room = {
          ...data,
        }
        if (data.files && data.files.length && data.files[0]) {
          this.vertical = this.room.files[0].vertical
        }
      })
    },
    async addProductsToCart(value) {
      const products = {
        entity: 'room',
        slug: this.room.slug,
      }

      if(typeof value === 'string') {
        products.ignore_add_alert = true
      }

      try {
        await this.$api.cart.addItemCart(products)
        if(typeof value === 'string') {
          this.products.forEach(item => {
            const product = {
              name: item.name,
              picture: item.files[0].url,
              slug: item.slug,
              price: item.price_type.netto,
              qty: item.cart_quantity,
            }
            if(item.status === 1) {
              this.$store.commit('cart/SET_CART_PRODUCT', product)
            }
          })
        }
      } catch (e) {
        const { response } = e
        if (response.status === 422) {
          this.$refs.modalRoom.onOpen(response.data.message)
        }
      }
    },
  },
}
