//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Breadcrumbs from '~/components/UI/Breadcrumbs.vue'
import {SET_BC} from '~/store/bc/types.bc'


export default {
  name: 'Index',
  components: {
    Breadcrumbs,
    RoomMiniatureItem: () =>
      import('~/components/modules/Miniatures/RoomMiniatureItem.vue'),
  },
  data() {
    return {
      categories: [],
      rooms: [],
    }
  },
  fetch() {
    return this.fetchRoom()
  },
  mounted() {
    // console.log('this.categories', this.categories)
    // console.log('this.rooms', this.rooms)
  },
  methods: {
    fetchRoom() {
      return this.$request.$get('/room/catalog/categories').then(res => {
        this.categories = res.data
        this.$store.commit(`bc/${SET_BC}`, [
          {name: 'Rooms', slug: '/'},
        ])
        return this.$request.$get('/room/catalog').then(res => {
          this.rooms = res.data.map(item => {
            return {
              ...item,
              src: item.files[0].preview_url,
              productsCount: `${item.items_count} ${this.$t('products')}`,
            }
          })
        })
      })
    },

  },
}
